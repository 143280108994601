/* global Vue */

import * as dompack from 'dompack';
import 'dompack/browserfix/reset.css';
import * as whintegration from '@mod-system/js/wh/integration';
window.whintegration = whintegration;
import '@mod-publisher/js/richcontent/all';
import './holtkamp.lang.json';
import { /*WWBasicSite, */ WWForms } from '@mod-ww/webdesigns/template/ww';
// import CookieWall from '@mod-ww/components/cookiewall/';

import anime from 'animejs/lib/anime.es.js';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

import { openLinksInNewWindow } from '@mod-publisher/js/linkhandler';
openLinksInNewWindow();

import { toggleExpanded } from './shared/js/utils';

import './holtkamp.scss';

import './widgets/widgets';
import './footer/footer';
import './header/header';
import ProductsBlock from './components/productsblock';

import './pages/search/search';

let sidebarEl,
  sidebarHeight = 0,
  pageContentEl,
  elHeaderDesktopMenu,
  offsetLeft = 0;

dompack.onDomReady(() => {
  // new WWBasicSite({});

  Vue.createApp({
    components: {
      ProductsBlock,
    },
    mounted() {
      setDomVars();
      window.addEventListener('resize', onResize);
      onResize();
      window.setTimeout(() => {
        setDomVars();
        onResize();
        dompack.qS('#header-desktop-menu').addEventListener('mouseenter', () => {
          recalcMenu();
        });
      }, 250);

      initHeaderSlider();

      const contentBlockerSearch = dompack.qS('.contentblockersearch');
      const searchInput = dompack.qS('#search');

      if (dompack.qS('#header-search')) {
        dompack.qS('#header-search').addEventListener('click', () => {
          contentBlockerSearch.classList.add('active');
          searchInput.focus();
        });
      }

      contentBlockerSearch.addEventListener('click', (evt) => {
        if (dompack.qS('.searchcontainer').contains(evt.target)) {
          return;
        }
        contentBlockerSearch.classList.remove('active');
        searchInput.value = '';
      });

      const contentBlocker = dompack.qS('.siteblocker');
      contentBlocker.addEventListener('click', (evt) => {
        // if (mobileMenu.contains(evt.target))
        //   return;
        contentBlocker.classList.remove('active');
      });

      // for debugging/styling:
      // dompack.qS('#show-mobile-menu').click();

      for (const col of dompack.qSA('.footer__col')) {
        const expander = col.querySelector('.footer__expand');
        const target = col.querySelector('.footer__content') || col.querySelector('.footer__links');

        if (!expander) {
          break;
        }

        expander.addEventListener('click', (evt) => {
          evt.preventDefault();
          toggleExpanded(target);

          dompack.toggleClass(expander, 'fa-plus');
          dompack.toggleClass(expander, 'fa-minus');
        });
      }

      new WWForms();
      dompack.registerMissed(dompack.qS('form[data-wh-form-handler]'));

      // for (const el of dompack.qSA('.wh-form__uploadfield')) {
      //   const elTrigger = el.querySelectorAll(
      //     '.wh-form__uploadfieldselect, .wh-form__uploadfieldfilename, .wh-form__uploadfield, .wh-form__uploadfieldinputholder'
      //   );
      //   const elParent = el.closest('.wh-form__fieldgroup');
      //   const elInput = elParent.querySelector('.wh-form__upload');
      //   for (const triggerElement of elTrigger) {
      //     triggerElement.addEventListener('click', (evt) => {
      //       console.log(evt);
      //       elInput.click();
      //     });
      //   }
      // }
    },
    data() {
      return {
        whintegration,
      };
    },
  }).mount('#app');
});

function setDomVars() {
  sidebarEl = dompack.qS('#sidebar');
  pageContentEl = dompack.qS('#pagecontent');
  elHeaderDesktopMenu = dompack.qS('#header-desktop-menu > .ww-menuitems');
}

function recalcMenu() {
  pageContentEl.style.marginTop = `${sidebarHeight * -1}px`;

  if (sidebarEl.style.display === 'none') {
    // const sidebarHeight = sidebarEl.offsetHeight;
    pageContentEl.style.marginTop = `${sidebarHeight * -1}px`;
  }

  dompack.qSA('.header__submenu--0 > .ww-menuitems > li').forEach((li) => {
    li.style.transform = `translateX(${parseInt(offsetLeft)}px)`;
  });
}

function onResize(evt) {
  if (sidebarEl) {
    sidebarHeight = sidebarEl.offsetHeight;
    offsetLeft = elHeaderDesktopMenu.getBoundingClientRect().x;
    recalcMenu();
  }
}

function initHeaderSlider() {
  const elHomeSlider = dompack.qS('.js-header-slider');
  if (!elHomeSlider) {
    return;
  }
  const slideDelay = 4000; // msecs
  const slideSpeed = 2000; // msecs
  const elSlideshowContainer = elHomeSlider.closest('.w-slideshow');
  const elProgressBar = elSlideshowContainer.querySelector('.slide-progress-bar-bar');

  function startAnimation(reversed = false) {
    anime({
      targets: elProgressBar,
      scaleX: reversed ? [1.0, 1.0] : [0.0, 1.0],
      opacity: reversed ? [1, 0] : [1, 1],
      duration: reversed ? slideSpeed : slideDelay,
      easing: 'linear',
    });
  }

  let swiperOptions = {
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    autoplay: {
      delay: slideDelay,
      disableOnInteraction: false,
    },
    speed: slideSpeed,
    loop: true,
    on: {
      init: function () {
        startAnimation();
      },
      slideChangeTransitionStart: function () {
        // reverse animation
        startAnimation(true);
      },
      slideChangeTransitionEnd: function () {
        startAnimation();
      },
    },
  };

  new Swiper(elHomeSlider.querySelector('.swiper'), swiperOptions);
}
