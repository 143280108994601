import { getTid } from '@mod-tollium/js/gettid';

export default {
  name: 'ProductsBlock',
  template: `
  <div>
    <!-- <products-block> (productsblock.es) -->
    <div class="w-productsblock widget-container widget-padding"
         :class="'w-productsblock--' + widgetDesignType">
      <div class="w-productsblock__title">{{ title }}</div>
      <div class="w-productsblock__filters" v-if="showFilters">
        {{ getTid("holtkamp:site.filter_on_type") }}:
        <a v-for="filter in filters"
           :class="{'selected':filter.selected === true}"
           @click.prevent="onFilterSelect(filter)">
          {{filter.title}}
        </a>
      </div>
      <div class="w-productsblock__products">
        <a v-for="product in filteredProducts" :href="product.link ? product.link : null" class="w-productsblock__product">
          <div class="w-productsblock__imagecontainer">
            <img class="w-productsblock__image"
                 :src="product.image.link"
                 :alt="product.title"
                 :style="'object-position:' + product.image.refpoint_backgroundposition + ';'">
          </div>
          <h2 class="w-productsblock__producttitle noline">{{product.title}}</h2>
        </a>
      </div>
    </div>
  </div>
  `,
  props: {
    title: { type: String },
    // converted to (JSON) object array
    products: {
      type: String,
      required: true,
    },
    widgetDesignType: {
      type: String,
      default: 'fontgoldinside',
    },
    showFilters: { type: Boolean },
  },
  data() {
    return {
      filters: [
        { title: getTid('holtkamp:site.filter_all'), tag: '', selected: true },
        { title: getTid('holtkamp:site.filter_meat'), tag: 'meat' },
        { title: getTid('holtkamp:site.filter_fish'), tag: 'fish' },
        { title: getTid('holtkamp:site.filter_vega'), tag: 'vega' },
      ],
      allProducts: [],
      filterValue: '',
    };
  },
  computed: {
    filteredProducts() {
      if (this.filterValue == '') {
        return this.allProducts;
      }
      return this.allProducts.filter((product) => product.type === this.filterValue);
    },
  },
  mounted() {
    this.allProducts = JSON.parse(this.products);
  },
  methods: {
    getTid,
    onFilterSelect(filter) {
      this.filterValue = filter.tag;
      this.filters = this.filters.map((filter) => {
        filter.selected = filter.tag == this.filterValue;
        return filter;
      });
    },
  },
};
