export function toggleExpanded(target) {
  let height = target.offsetHeight;
  const upperHeight = getMaxHeight(target);
  let multiplier = height === 0 ? 1 : -1;

  let interval = setInterval(() => {
    height += 2 * multiplier;
    target.style.height = `${height <= 0 ? 0 : height}px`;

    if ((multiplier === -1 && height <= 0) || (multiplier === 1 && height >= upperHeight)) {
      clearInterval(interval);
    }
  }, 1);
}

function getMaxHeight(target) {
  const initial = target.style.height;
  target.style.height = 'auto';
  const height = target.offsetHeight;
  target.style.height = initial;

  return height;
}
